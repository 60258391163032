var host = window.location.hostname;
//请求接口地址
var baseUrl;
var blockIdArr
if (host == 'zui.com') {
    //正式环境地址
    baseUrl="https://open-auth.tezign.com"
    blockIdArr={
        //使用技巧
        skillBlockID:485,
    }
}else{
    //测试环境地址
    baseUrl="https://open-auth.tezign.com"
    blockIdArr={
        //使用技巧
        skillBlockID:485,
    }
}
const interfaces = {
	//内容分发看板
    baseUrl,
    blockIdArr
}

export default interfaces;